<template>
    <div class="set-center-menus">
        <template v-for="item of setCenterRouter">
            <set-center-menu-item
                :key="item.id"
                :menu="item"
                :activeMenu="activeMenu"
            ></set-center-menu-item>
        </template>
    </div>
</template>

<script>
import SetCenterMenuItem from './MenuItem.vue'

export default {
    name: 'SetCenterMenus',
    components: {
        SetCenterMenuItem
    },
    props: {
        setCenterRouter: {
            type: Array
        },
        activeMenu: {
            type: String
        }
    },
    data() {
        return {
        }
    },
    computed: {
    },
    watch: {},
    created() {},
    mounted() {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.set-center-menus {
    width: 170px;
    height: calc(100% - 10px);
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: -2px 0px 8px 0px #E1E3E6;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    box-sizing: border-box;
    overflow-y: auto;
}
</style>
