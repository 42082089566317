<template>
    <div class="set-center-header">
        <div class="set-center-left">
            <img :src="imgLogo" alt="logo">
            <span class="title">后台设置中心</span>
        </div>
        <div class="set-center-right" @click="handleBack">
            <img :src="imgBack" alt="back">
            <span class="label">管理中心</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SetCenterHeader',
    computed: {
        imgLogo() {
            return require('@/assets/images/setCenterMainContainer/logo.png')
        },
        imgBack() {
            return require('@/assets/images/setCenterMainContainer/back.png')
        }
    },
    methods: {
        handleBack() {
            this.$emit('back')
        }
    }
}
</script>

<style lang="scss" scoped>
.set-center-header {
    width: 100%;
    background: linear-gradient(90deg, #296FF1 0%, #51A5F4 100%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.set-center-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;

    img {
        width: 34px;
        height: 34px;
        object-fit: contain;
        display: inline-block;
        margin-right: 20px;
    }

    .title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
    }
}

.set-center-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 32px;
    cursor: pointer;

    img {
        width: 42px;
        height: 42px;
        object-fit: contain;
        display: inline-block;
        margin-right: 5px;
    }

    .label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FEFFFF;
    }
}
</style>
