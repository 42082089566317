<template>
    <div
        class="set-center-menu-item"
        :class="{ 'set-center-menu-item_active': menu.name === activeMenu }"
        @click="handlerMenuClick">
        <span class="set-center-menu-title">{{ menu.title }}</span>
    </div>
</template>

<script>
export default {
    name: 'SetCenterMenuItem',
    props: {
        menu: {
            type: Object
        },
        activeMenu: {
            type: String
        }
    },
    methods: {
        handlerMenuClick() {
            this.$emit('menuClick', this.menu);
            this.$eventDispatch('menuClick', this.menu)
        }
    },
}
</script>

<style lang="scss" scoped>
.set-center-menu-item {
    width: 137px;
    height: 38px;
    cursor: pointer;
    text-align: center;
    line-height: 38px;
}
.set-center-menu-item_active {
    background: #3C7FFF;
    border-radius: 4px;
    color: #FFFFFF;
}
</style>
