 <!-- 校务管理 > 宿舍管理 > 宿舍分配 -->
<template>
    <div>
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-main">
                <expand-filter
                    :closeWidth="1190"
                    :formData="formData"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @changeSel="changeSel"
                >
                <div slot="dropdown" style="margin-left:auto;white-space:nowrap;">
                    <span style="display:inline-block;width:10px;"></span>
                    <el-button type="enquiry" @click="handleExport" v-hasPermi="exportBtn" :loading="exportLoading">
                        <span>导出</span>
                    </el-button>
                </div>
            </expand-filter>

                <table-data
                    ref="table"
                    v-loading="loadingTable"
                    :config="table_config"
                    :tableData="table_data"
                >
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
                
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    debounce
} from 'common-local';
import { mapState } from "vuex";
import TableData from "./Sub/TableData"
import { downloadFile, formatTreeData } from '@/libs/utils.js'
import { SchoolDormitory } from "@/models/SchoolDormitory";
import { SetMealOrderModel } from "@/models/SetMealOrder.js";
import ExpandFilter from "./Sub/ExpandFilter";
import onResize from "@/mixins/onResize"
import {getToken} from "@/libs/auth";
export default {
    mixins: [onResize],
    components: {
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        ExpandFilter,
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
        }),
        //导出
        exportBtn(){
            return ['setMealOrder:export']
        },
        headers() {
            return {
                Authorization: getToken(),
            }
        },
    },
    data() {
        return {
            listQuery: {
                schoolId: '',
                comboName: '',

                buyType: '',
                startTime: '',//支付日期开始时间
                endTime: '',//支付日期结束时间
                endTimeStart: '',//到期日期开始时间
                endTimeEnd: '',//到期日期结束时间
                pageNum: 1,
                pageRow: 20,
                productId: "smart_star",
                jobScope:["1","2","3"]
            },
            subjects: [],
            total: 0,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '年级',
                        key: 'gradeName',
                        list: []
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '收费项名称',
                        key: 'comboName',
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '收费项属性',
                        key: 'buyType',
                        list: [
                            { label: '套餐', value: '1' },
                            { label: 'VIP', value: '2' },
                        ]
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '支付方式',
                        key: 'payType',
                        list: [
                            { label: '微信', value: '1' },
                        ]
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '支付状态',
                        key: 'orderStatus',
                        list: [
                            { label: '已支付', value: '2' },
                            { label: '已退款', value: '4' },
                        ]
                    },
                    {
                        type: "datePick",
                        value: "",
                        startPlaceholder: "支付开始日期",
                        endPlaceholder: "支付结束日期",
                        key: "timeArr",
                        clearable: true
                    },
                    {
                        type: "datePick",
                        value: "",
                        startPlaceholder: "到期开始日期",
                        endPlaceholder: "到期结束日期",
                        key: "endTimeArr",
                        clearable: true
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '商户单号',
                        key: 'orderNumber'
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '支付人手机号',
                        key: 'payPersonPhone'
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '学生姓名',
                        key: 'studentName'
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '学生学号',
                        key: 'studyNo'
                    },
                    
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'primary',
                        auth:['setMealOrder:list']
                    },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset',
                        auth:['setMealOrder:list']
                    }
                ],
                btnFormType: true
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "交易单号",
                        prop: "orderNo",
                        type: "popoverText",
						labelWidth:'180px',
                        align: 'center',
                        
                    },
                    {
                        label: "商户单号",
                        prop: "orderNumber",
                        labelWidth:'120px',
                        align: 'center',
                        
                    },
                    {
                        label: "名称",
                        labelDescription: "收费项名称",
                        prop: "comboName",
                        align: 'center'
                    },
                    {   
                        label: "属性",
                        labelDescription: "收费项属性",
                        prop: "buyType",
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            return {
                                '1': "套餐",
                                '2': "VIP",
                                '3': "单模块",
                            }[+row.buyType];
                        }
                    },
                    {   
                        label: "支付金额",
                        labelDescription: "支付金额（元）",
                        prop: "payAmount",
                        align: 'center',
                        
                    },
                    {
                        label: "支付时间",
                        prop: "payTime",
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            return row.payTime ? row.payTime.slice(0, 16):'-'
                        },
                    },
                    {
                        label: "到期时间",
                        prop: "endTime",
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            return row.endTime ? row.endTime.slice(0, 16):'-'
                        },
                    },
                    {
                        label: "支付方式",
                        prop: "payTypeName",
                        align: 'center'
                    },
                    {   
                        label: "手机号",
                        labelDescription: "支付人手机号",
                        prop: "payPersonPhone",
                        align: 'center'
                    },
                    {
                        label: "学生姓名",
                        prop: "studentName",
                        align: 'center'
                    },
                    {
                        label: "学生学号",
                        prop: "studyNo",
                        align: 'center'
                    },
                    {   
                        label: "唯一号",
                        labelDescription: "学生唯一号",
                        prop: "onlyCode",
                        align: 'center'
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: 'center'
                    },
                    {
                        label: "级部",
                        prop: "schoolStageName",
                        align: 'center'
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: 'center'
                    },
                    {
                        label: "订单状态",
                        prop: "orderStatus",
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            return {
                                '1': "待支付",
                                '2': "已支付",
                                '3': "已取消",
                                '4': "已退款",
                            }[+row.orderStatus];
                        }
                    },
                    
                ],
                check: false,
                height: '',
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            dialogLoading: false,
            // 分配
            dialogObj: {
                title: '宿舍分配',
                dialogVisible: false,
                width: '900px'
            },
            ruleForm: {
                productId: "smart_star"
            },
            dialogTableList: [],
            dormitoryList: [],

            tableListSel: [],
            firstTime: true,
            bedNum:'',
            exportLoading: false
        }
    },
    created() {
        this.firstTime = false;
        this.ruleForm.schoolId = this.listQuery.schoolId = this.schoolId
        this.init()
    },
    mounted() {
    },
    activated() {
        if (this.firstTime) {
            this.init();
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    methods: {
        init(){

            this.getList()
            this.getGradeList()

        },
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            let data = {};
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });

            if (data.timeArr && data.timeArr.length > 0) {
                this.listQuery.startTime = `${data.timeArr[0]} 00:00:00`;
                this.listQuery.endTime = `${data.timeArr[1]} 23:59:59`;
            } else {
                this.listQuery.startTime = "";
                this.listQuery.endTime = "";
            }

            if (data.endTimeArr && data.endTimeArr.length > 0) {
                this.listQuery.endTimeStart = `${data.endTimeArr[0]} 00:00:00`;
                this.listQuery.endTimeEnd = `${data.endTimeArr[1]} 23:59:59`;
            } else {
                this.listQuery.endTimeStart = "";
                this.listQuery.endTimeEnd = "";
            }

            let form = this.listQuery;
            delete form.timeArr;
            delete form.endTimeArr;

            this.loadingTable = true
            const setMealOrderModel = new SetMealOrderModel()
            setMealOrderModel.getSetMealOrderList(form).then((res) => {
                if (res.data.code === '200') {
                    this.table_data = res.data.data.list
                    this.total = res.data.data.totalCount
                } else {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false
            })
        },
        // 获取年级列表
        getGradeList() {
            const gradeIndex = this.formData.data.findIndex(
                (i) => i.key === "gradeName",
            );
            const param = {
                schoolId: this.ruleForm.schoolId,
            };
            this._fet("/operate/menuOpen/queryGradeList", param).then((res) => {
                this.handleRes(res, () => {
                    this.formData.data[gradeIndex].list = res.data.data.map(
                        (i) => ({label: i.name, value: i.name}),
                    );
                });
            });
        },
       
        changeSel(data) {
            
        },
        clickBtn(data) {
            switch (data.item.fn) {
                case 'primary': // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    this.getList(1);
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        if (item.key === 'timeArr' || item.key === 'endTimeArr') {
                            this.listQuery[item.key] = []
                            item.value = [];
                        } else {
                            this.listQuery[item.key] = ''
                            item.value = '';
                        }
                    })
                    this.getList(1);
                    break;
            }
        },



        /*
            handleExport            导出
         */
        handleExport() {
            let form = {
                schoolId: this.schoolId,
                productId: "smart_star",
                jobScope:["1","2","3"]
            };
            const filter = this.formData.data.filter((i) => i.value);
            if (filter.length > 0) {
                filter.forEach((item) => {
                    form[item.key] = item.value;
                });

                if (form.timeArr && form.timeArr.length > 0) {
                        form.startTime = `${form.timeArr[0]} 00:00:00`;
                        form.endTime = `${form.timeArr[1]} 23:59:59`;
                    } else {
                        form.startTime = "";
                        form.endTime = "";
                    }

                    if (form.endTimeArr && form.endTimeArr.length > 0) {
                        form.endTimeStart = `${form.endTimeArr[0]} 00:00:00`;
                        form.endTimeEnd = `${form.endTimeArr[1]} 23:59:59`;
                    } else {
                        form.endTimeStart = "";
                        form.endTimeEnd = "";
                    }
            } else if (this.tableListSel.length > 0) {
                form.ids = this.tableListSel.map((i) => i.id);
            }
            this.exportLoading = true;
            downloadFile({url: '/consume/schoolSetMealOrder/export', form: form}, () => {
                this.$message.success('导出成功')
                this.exportLoading = false;
            }, () => {

            })
        },

        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
    }
}
</script>

<style scoped lang="scss">

.upload-demo {
    display: inline-block;
}
.img {
    width: 14px;
    float: left;
    margin-top: 2px;
    padding-right: 6px;
}
.el-button {
    font-size: 14px;
    margin-left: 10px;
}
.download-btn {
    border: 0;
    background-color: #3dc5f2;
    &:hover {
        background-color: #5ecff5;
    }
}
</style>
